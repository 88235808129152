.details-card {
  background-color: white;
  border-radius: 16px;
  padding: 2rem;
  position: relative;
}
.extended-w-1 {
  width: 100px;
  max-width: 100%;
}
.extended-w-2 {
  width: 140px;
  max-width: 100%;
}
.details-card .company-img {
  border: 2px solid rgba(0, 46, 102, 0.2);
  border-radius: 100px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.details-card div.company-img svg {
  transform: scale(1.5);
  fill: gray;
}

.details-card .seller-badge {
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-size: 0.8rem;
  background-color: #00b87f;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
}

.circle {
  width: 1rem;
  height: 1rem;
  background-color: var(--main-blue);
  border-radius: 1rem;
}
.line-through {
  position: absolute;
  height: 100%;
  width: 4px;
  left: calc(0.5rem - 2px);
  background-color: var(--main-blue);
}
.reservaja-card {
  margin-top: -5rem;
  overflow: hidden;
  position: relative;
  aspect-ratio: 5 / 3;
  max-width: 80%;
  width: 400px;
  background: linear-gradient(
    78.92deg,
    #002e66 0%,
    #185dc3 71.33%,
    #f0a400 115.88%
  );
  box-shadow: 0px 4px 8px rgba(24, 93, 195, 0.2),
    0px 16px 24px rgba(241, 163, 0, 0.15);
  border-radius: 32px;
}

.reservaja-card .card-container {
  color: white;
  position: relative;
  z-index: 1;
  padding: 32px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.reservaja-card svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card-bill {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin-left: 32px;
}
.rounded-full {
  border-radius: 200px;
}
.mode-indicator {
  top: 0;
  left: 0;
  background-color: var(--main-orange);
  height: 100%;
  z-index: 0;
}
.extreme-margin-top {
  margin-top: 5rem;
}
.details-card .edit {
  padding: 8px 24px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-size: 0.8rem;
  border-radius: 4px;
}
