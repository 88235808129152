.container.perfil {
  min-height: 100%;
}
.profile-card {
  width: 100%;
  border-radius: 4px;
  max-width: 500px;
  background-color: white;
  text-align: center;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.profile-main {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.profile-footer .logout {
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.profile-footer .logout:hover {
  color: var(--main-blue);
}
.profile-footer {
  background: linear-gradient(90deg, #e4eff3 0%, #def7ff 100%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.icon {
  height: 80px;
  width: 80px;
  margin-top: -40px;
  background-color: white;
  border-radius: 50%;
  padding: 12px;
  margin-left: auto;
  margin-right: auto;
  fill: var(--main-blue);
  border: 2px solid #e4eff3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.enabled-circle {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--success);
  border-radius: 1.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.disabled-circle {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--error);
  border-radius: 1.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.badge {
  padding: 0.2rem 1rem;
  font-size: 0.8rem;
  background-color: var(--main-blue);
  color: white;
  border-radius: 2rem;
}
