.context-menu {
  min-width: 180px;
  position: fixed;
  z-index: 10;
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: rgba(91, 96, 165, 0.157) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  transition: all 150ms ease-in-out;
}

.context-menu .context-menu__item {
  padding: 8px;
  border-radius: 4px;
  transition: all 150ms ease-in-out;
}

.context-menu .context-menu__item:hover {
  background-color: #5b60a5a4;
  padding: 8px 16px;
  cursor: pointer;
  color: white;
}
