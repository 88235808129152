.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
}
.action-button.pv-4 {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.modal-container {
  background-color: #f9f9fb;
  width: 50%;
  max-height: 75%;
  color: #000;
  padding: 24px 0;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
}
.close {
  position: absolute;
  top: 24px;
  right: 24px;
}
.close button {
  color: #000;
  border: none;
  background-color: transparent;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.modal-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 48px;
}
.modal-content h4 {
  font-size: 20px;
  margin-bottom: 24px;
  margin-top: 24px;
}
