.checkbox,
.radio-button {
  display: flex;
  color: #909090;
  overflow: hidden;
  margin-right: 16px;
}
.checkbox input,
.radio-button input {
  display: none;
}
.checkbox .input,
.radio-button .input {
  position: relative;
  display: flex;
  margin-right: 16px;
  border-radius: 4px;
  border: none;
  width: 20px;
  height: 20px;
  padding: 3px;
  background: linear-gradient(
    45deg,
    rgba(24, 93, 194, 1) 10%,
    rgba(0, 47, 104, 1) 60%,
    rgba(241, 163, 0, 1) 120%
  );
}
.checkbox input:checked + .input::before,
.radio-button input:checked + .input::before {
  transform: scale(0);
}
.checkbox .input::before,
.radio-button .input::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: scale(0.8);
  transition: all 150ms ease-in-out;
  background-color: #fefdff;
}
.radio-button .input,
.radio-button .input::before {
  border-radius: 24px;
}
.checkbox input:checked + .input svg,
.radio-button input:checked + .input svg {
  transition: all 150ms ease-in;
  opacity: 1;
}
.checkbox .input svg,
.radio-button .input svg {
  transition: all 150ms ease-in-out;
  opacity: 0;
}
