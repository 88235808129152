header {
  background-color: #002f68;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  position: fixed;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-left: 72px;
  margin-right: 72px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.logo img {
  height: 28px;
}
.nav-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}
.nav-list a {
  color: white;
  text-decoration: none;
  padding: 12px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 200ms ease-in-out;
}
.seller {
  padding-top: 100px;
  padding-left: 24px;
  padding-right: 24px;
  height: 100vh;
}
.nav-list a.active,
.nav-list a:hover {
  background-color: var(--main-orange);
}
