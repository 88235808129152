.file-input {
  box-shadow: 0 0 1px 1px #ddd;
  border-radius: 4px;
  padding: 12px 16px;
  color: var(--light-gray);
}
.file-input input {
  display: none;
}
.file-input svg {
  height: 32px;
  margin-right: 16px;
  fill: var(--main-blue);
  align-self: center;
}
.file-input img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  object-fit: cover;
  margin-right: 1rem;
}
.file-input img.br-0 {
  border-radius: 0;
  object-fit: contain;
}
