.form-header h2 {
  color: #373b53;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
}
.container p {
  color: #373b53;
  font-weight: 300;
  font-size: 18px;
}
