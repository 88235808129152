:root {
  --light-gray: #909090;
  --dark-gray: #4a5568;
  --main-blue: #002f68;
  --main-orange: #f1a300;
  --success: #43d422;
  --error: #f75048;
}

* {
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

.fill-main {
  fill: var(--main-blue);
}

input {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
}
input {
  font-size: 1rem;
}
.text-sm {
  font-size: 0.8rem;
}
.text-base {
  font-size: 1rem;
}
body {
  min-height: 100vh;
  background-color: #f9f9fb;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: stretch;
}
#root {
  min-height: 100vh;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.br-1 {
  border-radius: 4px;
}

.br-2 {
  border-radius: 8px;
}

.br-3 {
  border-radius: 12px;
}

.br-4 {
  border-radius: 16px;
}

button {
  border-radius: none;
  border: none;
  border-radius: 4px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

::placeholder {
  color: #999;
}

.st0 {
  fill: var(--main-blue);
}
.st1 {
  fill: var(--main-orange);
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin-bottom: 3rem;
}
.text-opaque {
  color: #afb5b8;
}

.blue-text {
  color: var(--main-blue);
}

.blue-bg,
.bg-blue {
  background-color: var(--main-blue);
}
.orange-text {
  color: var(--main-orange);
}

.orange-bg,
.bg-orange {
  background-color: var(--main-orange);
}

.uppercase {
  text-transform: uppercase;
}

.text-white {
  color: white;
}

.bg-white {
  background-color: white;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.rounded {
  border-radius: 4px;
}

.card {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 1.5rem;
  background-color: white;
}
.cursor-pointer {
  cursor: pointer;
}
.no-shadow {
  box-shadow: none;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.5rem;
}
.p-2 {
  padding: 1rem;
}
.p-3 {
  padding: 1.5rem;
}
.p-4 {
  padding: 2rem;
}
.ph-0 {
  padding-left: 0;
  padding-right: 0;
}
.ph-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.ph-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ph-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.ph-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.pv-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pv-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pv-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.pv-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 0.5rem;
}
.pl-2 {
  padding-left: 1rem;
}
.pl-3 {
  padding-left: 1.5rem;
}
.pl-4 {
  padding-left: 2rem;
}
.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 0.5rem;
}
.pr-2 {
  padding-right: 1rem;
}
.pr-3 {
  padding-right: 1.5rem;
}
.pr-4 {
  padding-right: 2rem;
}
.m-0 {
  margin: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.mh-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mh-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mh-3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mh-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 2rem;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.5rem;
}
.ml-2 {
  margin-left: 1rem;
}
.ml-3 {
  margin-left: 1.5rem;
}
.ml-4 {
  margin-left: 2rem;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mr-2 {
  margin-right: 1rem;
}
.mr-3 {
  margin-right: 1.5rem;
}
.mr-4 {
  margin-right: 2rem;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.flex-1 {
  flex: 1;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.font-medium {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.ls-1 {
  letter-spacing: 1px;
}

.items-center {
  align-items: center;
}
.items-between {
  align-items: space-between;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-1 {
  gap: 0.5rem;
}
.gap-2 {
  gap: 1rem;
}
.gap-3 {
  gap: 1.5rem;
}
.gap-4 {
  gap: 2rem;
}

.w-25 {
  width: 25%;
}
.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-full {
  width: 100%;
}
.grid-col-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.grid-col-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid-col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.self-end {
  margin-left: auto;
}
.self-start {
  margin-right: auto;
}

/* @media (max-width: 768px) {
} */

@media (max-width: 1024px) {
  .grid-col-3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .grid-col-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .grid-col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .grid-col-3 {
    display: grid;
    grid-template-columns: 1fr;
  }
  .grid-col-4 {
    display: grid;
    grid-template-columns: 1fr;
  }
  .grid-col-2 {
    display: grid;
    grid-template-columns: 1fr;
  }
}
